import React from "react";
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    SelectInput,
    DateField,
    TextField,
    BooleanField,
    EditButton,
    DisabledInput,
    TextInput,
    LongTextInput,
    DateInput,
    BulkActions,
    DeleteButton,
    NumberField,
    BulkDeleteAction,
    ReferenceManyField,
    ReferenceArrayField,
    Filter,
    SingleFieldList,
    ChipField,
    ReferenceArrayInput,
    SelectArrayInput
} from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import ReviewChallangesAction from "./ReviewChallangesAction"
export const ChallengeIcon = BookIcon;


const ChallengeFilter = (props) => (
    <Filter {...props}>
        <ReferenceArrayInput source="sets" reference="sets">
            <SelectArrayInput optionText="id" />
        </ReferenceArrayInput>
    </Filter>
);

export const ChallengeList = props => (
    <List {...props} bulkActions={<ChallengeBulkActions />} filters={<ChallengeFilter />} >
        <Datagrid>
            <TextField source="challenge" />
            <NumberField source="difficulty" />
            <NumberField source="language" />
            <ReferenceArrayField label="Sets" reference="sets" source="sets">
                <SingleFieldList>
                    <ChipField source="id" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);


const ChallengeBulkActions = props => (
    <BulkActions {...props}>
        <ReviewChallangesAction label="Review" />
        {/* Add the default bulk delete action */}
    </BulkActions>
);




const validateChallenge = (values) => {
    const errors = {};
    if (!values.challenge) {
        errors.challenge = ['Enter a text'];
    }
    if (!values.difficulty) {
        errors.difficulty = ['Difficulty is required'];
    }
    if (values.sets && values.sets.some((item) => !item.startsWith(values.language))) {
        errors.sets = ['Please review language settings'];
    }
    return errors
};


export const ChallengeCreate = props => (
    <Create title="Create a Challenge" {...props}>
        <SimpleForm validate={validateChallenge}>
            <p>Wenn ich du wäre, </p>
            <LongTextInput source="challenge" label="würde ich..." />
            <SelectInput
                source="difficulty"
                choices={[
                    { id: 1, name: "1" },
                    { id: 2, name: "2" },
                    { id: 3, name: "3" }
                ]}
            />
            <SelectInput
                source="language"
                choices={[
                    { id: "de", name: "de" },
                    { id: "en", name: "en" },
                    { id: "es", name: "es" }
                ]}
            />
            <ReferenceArrayInput source="sets" reference="sets">
                <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

export const ChallengeEdit = props => (
    <Edit {...props} >
        <SimpleForm validate={validateChallenge}>
            <p>Wenn ich du wäre, </p>
            <LongTextInput source="challenge" label="würde ich..." />
            <SelectInput
                source="difficulty"
                choices={[
                    { id: 1, name: "1" },
                    { id: 2, name: "2" },
                    { id: 3, name: "3" }
                ]}
            />
            <SelectInput
                source="language"
                choices={[
                    { id: "de", name: "de" },
                    { id: "en", name: "en" },
                    { id: "es", name: "es" }
                ]}
            />
            <ReferenceArrayInput source="sets" reference="sets">
                <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);