import React from "react";
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    SelectInput,
    DateField,
    TextField,
    BooleanField,
    EditButton,
    DisabledInput,
    TextInput,
    LongTextInput,
    DateInput,
    BulkActions,
    DeleteButton,
    NumberField,
    BulkDeleteAction,
    ReferenceManyField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ReferenceArrayInput,
    SelectArrayInput
} from "react-admin";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import BookIcon from "@material-ui/icons/Book";
export const SubmissionIcon = BookIcon;

const CreateRelatedCommentButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/challenges/create',
            state: { record: { challenge: record.challenge, difficulty: record.difficulty } },
        }}
    >
        Review
    </Button>
);

export const SubmissionList = props => (
    <List {...props} >
        <Datagrid>
            <TextField source="challenge" />
            <TextField source="place" />
            <NumberField source="difficulty" />
            <CreateRelatedCommentButton />
            <DeleteButton />
        </Datagrid>
    </List>
);