import * as React from "react";
import { TabbedForm, FormTab, LongTextInput, NumberInput, NumberField, Tab, TabbedShowLayout, ArrayField, ArrayInput, BooleanField, BooleanInput, ChipField, Create, Datagrid, Edit, EditButton, Filter, List, RichTextField, SelectInput, Show, ShowButton, SimpleForm, SimpleFormIterator, SimpleShowLayout, SingleFieldList, TextField, TextInput } from "react-admin";


const SetFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="title" alwaysOn />
    </Filter>
);

export const SetList = (props) => (
    <List {...props} filters={<SetFilter />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <RichTextField source="description" />
            <BooleanField source="free" />
            <BooleanField source="active" />
            <ShowButton />
            <EditButton />
            {/* <DeleteButton label="" redirect={false}/> */}
        </Datagrid>
    </List>
);

export const SetShow = (props) => (
    < Show {...props}>
        <TabbedShowLayout>
            <Tab label="General">
                <TextField source="title" />
                <TextField source="language" />
                <RichTextField source="description" />
                <BooleanField source="free" />
                <BooleanField source="active" />
            </Tab>
        </TabbedShowLayout>
    </Show >
);

export const SetCreate = (props) => (
    <Create {...props} >
        <TabbedForm>
            <FormTab label="General">
                <TextInput source="title" label="Title" />
                <TextInput source="description" label="Description" />
                <SelectInput source="language" choices={[
                    { id: 'de', name: 'de' },
                    { id: 'en', name: 'en' },
                    { id: 'es', name: 'es' },
                ]} />
                <BooleanInput label="Free" source="free" />
                <BooleanInput label="Active" source="active" />
            </FormTab>
        </TabbedForm>
    </Create>
);


export const SetEdit = (props) => (<Edit {...props}>
    <TabbedForm>
        <FormTab label="General">
            <TextInput source="title" label="Title" />
            <TextInput source="description" label="Description" />
            <SelectInput source="language" choices={[
                { id: 'de', name: 'de' },
                { id: 'en', name: 'en' },
                { id: 'es', name: 'es' },
            ]} />
            <BooleanInput label="Free" source="free" />
            <BooleanInput label="Active" source="active" />
        </FormTab>
    </TabbedForm>
</Edit>)

