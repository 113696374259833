// in ./ResetViewsAction.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Confirm } from 'react-admin';
import { crudUpdateMany } from 'ra-core';

class ReviewChallengesAction extends Component {
    handleDialogClose = () => {
        this.props.onExit();
    };

    handleConfirm = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        crudUpdateMany(resource, selectedIds, { reviewed: true }, basePath);
        this.props.onExit();
    };

    render() {
        return (
            <Confirm
                isOpen={true}
                title="Review Challenges"
                content="Are you sure you want to mark these challenges as reviewed??"
                onConfirm={this.handleConfirm}
                onClose={this.handleDialogClose}
            />
        );
    }
}

export default connect(undefined, { crudUpdateMany })(ReviewChallengesAction);