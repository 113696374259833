import React from 'react';
import './App.css';

import { SetList, SetShow, SetCreate, SetEdit } from "./sets";
import { ChallengeList, ChallengeShow, ChallengeCreate, ChallengeEdit } from "./challenges";
import { SubmissionList } from "./submissions";
import { Admin, Resource } from 'react-admin';

import PostIcon from '@material-ui/icons/Book';
import SubmissionsIcon from '@material-ui/icons/Email';


import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  FirebaseRealTimeSaga
} from 'react-admin-firebase';

const config = {
  apiKey: "AIzaSyDQmd86RUlNC2uYPnKFXgnCAfw2dCPcU0w",
    authDomain: "ifiwereyou-9cef6.firebaseapp.com",
    databaseURL: "https://ifiwereyou-9cef6.firebaseio.com",
    projectId: "ifiwereyou-9cef6",
    storageBucket: "ifiwereyou-9cef6.appspot.com",
    messagingSenderId: "239618278686",
    appId: "1:239618278686:web:5c5e89ec6b59a043"
};

const options = {
  logging: true
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="sets"
          list={SetList}
          show={SetShow}
          create={SetCreate}
          edit={SetEdit}
        />
        <Resource
          icon={PostIcon}
          name="challenges"
          list={ChallengeList}
          create={ChallengeCreate}
          edit={ChallengeEdit}
        />
        <Resource
          icon={SubmissionsIcon}
          name="submissions"
          list={SubmissionList}
        />
      </Admin>
    );
  }
}

export default App;

